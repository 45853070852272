import { useTexture } from "@react-three/drei"
import { useControls } from 'leva'

export default function Background()
{
    const { bg_Width, bg_Height, bg_x, bg_y, bg_z } = useControls('Background',{
        bg_Width:
        {
            value: 45,
            min: 1,
            max: 100,
            step: 1
        },
        bg_Height:
        {
            value: 25,
            min: 1,
            max: 100,
            step: 1
        },
        bg_x:
        {
            value: 0,
            min: -50,
            max: 50,
            step: 0.01
        },
        bg_y:
        {
            value: 11,
            min: -50,
            max: 50,
            step: 0.01
        },
        bg_z:
        {
            value: -10.1,
            min: -50,
            max: 50,
            step: 0.01
        }
    })

    const bgTex = useTexture('./textures/aSunset3x2_02.jpg')

    return <>
        <mesh position={[bg_x, bg_y, bg_z]}>
            <planeGeometry args={[bg_Width, bg_Height ]} />
            <meshStandardMaterial emissive={'#888888'} emissiveMap ={ bgTex } emissiveIntensity={ 1 } />
        </mesh>
    </>
}