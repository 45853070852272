/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useControls } from 'leva'

export default function Island(props){

  const { landscapeColor, landscapeOutlineEmissive, palmTreeColor, palmTreeOutlineEmissive } = useControls('Island',
  {
    landscapeColor: '#0000ff',
    landscapeOutlineEmissive: '#FF0055',
    palmTreeColor: '#303030',
    palmTreeOutlineEmissive: '#00FFFF'
  })

  const { nodes, materials } = useGLTF("./models/Island.gltf");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Landscape.geometry}
        position={[0, -0.1, 0]}
      >
        <meshStandardMaterial color={landscapeColor} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Landscape_Outline.geometry}
        position={[0, -0.1, 0]}
      >
        <meshStandardMaterial color={0x000000} emissive={landscapeOutlineEmissive}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PalmTree1.geometry}
        position={[-1.2, 1.2, -0.1]}
        rotation={[0, 0, 0.175]}
      >
        <meshStandardMaterial color={palmTreeColor} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PalmTree1_Outline.geometry}
        position={[-1.2, 1.2, -0.1]}
        rotation={[0, 0, 0.175]}
      >
        <meshStandardMaterial color={0x000000} emissive={palmTreeOutlineEmissive} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PalmTree2.geometry}
        position={[0.519, 1.2, -0.1]}
        rotation={[Math.PI, Math.PI / 9, -2.88]}
      >
        <meshStandardMaterial color={palmTreeColor} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PalmTree2_Outline.geometry}
        position={[0.519, 1.2, -0.1]}
        rotation={[Math.PI, Math.PI / 9, -2.88]}
      >
        <meshStandardMaterial color={0x000000} emissive={palmTreeOutlineEmissive} />
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/Island.gltf");
