import { OrbitControls, Sparkles } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import { useControls } from 'leva'
import Island from './components/Island.jsx'
import Sun from './components/Sun.jsx'
import Sea from './components/Sea.jsx'
import Background from './components/Background.jsx'

export default function Experience()
{
    const { islandZPosition } = useControls('Island',{
        islandZPosition:
        {
            value: 0,
            min: -20,
            max: 20,
            step: 0.01
        }
    })

    return <>

        <color args={ [ '#181818' ] } attach={"background"} />

        {/* <Perf position="top-left" /> */}

        {/* <OrbitControls makeDefault /> */}

        <directionalLight position={ [ 1, 2, 3 ] } intensity={ 0 } />
        <ambientLight intensity={ 0.00 } />

        <Island position-z={ islandZPosition } />

        <Sun />

        <Sea />

        <Background />

        <Sparkles 
            size={ 6 }
            color={ '#cccc00' }
            scale={ [50, 10, 50] }
            position-y={ 5 }
            speed={ 0.2 }
            count={ 500 }
        />

    </>
}