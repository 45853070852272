import { useRef } from 'react'
import * as THREE from 'three'
import { useFrame, extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import { useControls } from 'leva'
import sunVertexShader from '../shaders/sun/vertex.glsl'
import sunFragmentShader from '../shaders/sun/fragment.glsl'

const SunMaterial = shaderMaterial(
    {
        uTime: 0,
        uGradientPitch: 1,
        uColorA: new THREE.Color('yellow'),
        uColorB: new THREE.Color('magenta')
    },
    sunVertexShader,
    sunFragmentShader
)

extend({ SunMaterial })

export default function Sun()
{
    const sunMaterial = useRef()
    useFrame((state, delta) =>{
        sunMaterial.current.uTime += delta
    })

    const { Sun_Radius, Sun_Segments, Sun_x, Sun_y, Sun_z } = useControls('Sun',{
        Sun_Radius:
        {
            value: 6,
            min: 0,
            max: 20,
            step: 0.01
        },
        Sun_Segments:
        {
            value: 128,
            min: 4,
            max: 256,
            step: 1
        },
        Sun_x:
        {
            value: 0,
            min: -20,
            max: 20,
            step: 0.01
        },
        Sun_y:
        {
            value: 3,
            min: -20,
            max: 20,
            step: 0.01
        },
        Sun_z:
        {
            value: -10,
            min: -20,
            max: 20,
            step: 0.01
        }
    })

    return <>
        <mesh position={[Sun_x, Sun_y, Sun_z]} >
            <circleGeometry args={ [ Sun_Radius, Sun_Segments ] } />
            <sunMaterial ref={ sunMaterial } transparent= {true} />
        </mesh>
    </>
}