import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { Leva } from 'leva'
import ComingSoon from './components/ComingSoon'
import { Suspense } from 'react'
import { Loader } from '@react-three/drei'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <Leva hidden />
        <Canvas
            flat
            camera={ {
                fov: 31.417,
                near: 0.1,
                far: 100,
                position: [ 0, 1.8, 20],
                rotation: [0, 0, 0]
            } }
        >
            <fog attach="fog" args={['magenta', 25, 45]} />
            <Suspense fallback={null}>
                <Experience />
            </Suspense>
        </Canvas>
        <Loader />
        <ComingSoon />
    </>
)